<template>
    <v-card :color="$vuetify.theme.dark ? 'almostblack' : 'white'">
        <v-card-title class="title mapbox-popup-title text-center justify-center font-weight-light">
            {{ $_.trim(properties.title) }}
        </v-card-title>
        <v-card-subtitle class="subtitle-2 mapbox-popup-subtitle text-center pb-0 font-weight-light">
            {{ $_.trim(properties.location) }}, {{ properties.state }}
        </v-card-subtitle>
        <v-card-text>
            <table width="100%">
                <tr>
                    <th class="text-left">Frequency</th>
                    <td>{{ $_.isString(properties.frequency) ? properties.frequency.replace('46X', '462') : '--' }} MHz</td>
                </tr>
                <tr>
                    <th class="text-left">Output Tone</th>
                    <td>{{ properties.toneOut || 'Sign In to View' }}</td>
                </tr>
                <tr>
                    <th class="text-left">Input Tone</th>
                    <td>{{ properties.toneIn || 'Sign In to View' }}</td>
                </tr>
                <tr>
                    <th class="text-left">Repeater Type</th>
                    <td>{{ properties.type }}</td>
                </tr>
                <tr>
                    <th class="text-left">Estimated Range</th>
                    <td>{{ range(properties.radius, properties.haat) }}</td>
                </tr>
                <tr v-if="config.NETWORK_ENABLED && properties.node && properties.node !== 'null'">
                    <th class="text-left">Node Number</th>
                    <td>
                        <v-btn v-blur color="rfaccent2" dark small :href="'https://network.mygmrs.com/map/' + properties.node">
                            {{ properties.node }}
                            <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                        </v-btn>
                    </td>
                </tr>
                <tr>
                    <th class="text-left">Last Updated</th>
                    <td>{{ updatedFrom }}</td>
                </tr>
                <tr v-if="!properties.hideViewDetails">
                    <td colspan="2" align="center">
                        <v-btn small v-blur color="rfaccent2" dark @click="viewDetails(properties.id)" class="mt-5">
                            View Details
                            <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </table>
        </v-card-text>
    </v-card>
</template>

<style>
    .mapboxgl-popup {
        min-width: 300px;
    }

    .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
        border-bottom-color: var(--v-almostblack-base) !important;
    }

    .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
        border-top-color: var(--v-almostblack-base) !important;
    }

    .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
        border-right-color: var(--v-almostblack-base) !important;
    }

    .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
        border-left-color: var(--v-almostblack-base) !important;
    }

    .mapboxgl-popup-content {
        padding: 0;
        background-color: transparent;
    }

    .mapboxgl-popup-close-button {
        color: gray;
        font-size: 20px;
        z-index: 3;
        padding: 8px;
    }

    .mapbox-popup-title {
        line-height: 1.3rem !important;
        word-break: break-word !important;
    }

    .mapbox-popup-subtitle {
        line-height: 1.3rem !important;
    }
</style>

<script>
    import config from "../config";
    import moment from "moment";

    export default {
        name: "MapPopupContent",

        props: ["properties"],

        computed: {
            updatedFrom() {
                return moment(this.properties.modified).fromNow();
            },
        },

        data: () => ({
            config: config
        }),

        methods: {
            range(radius, haat) {
                if (!radius) {
                    const distRepeater = ((4.12 * Math.sqrt(haat / 3.28084)) * 0.621371);
                    const distRadio = ((4.12 * Math.sqrt(5 / 3.28084)) * 0.621371);
                    return `${Math.round((distRadio + distRepeater) * 10) / 10} Miles`;
                } else {
                    return `${radius} Miles`;
                }
            },
            viewDetails(id) {
                this.$emit('view-details', id);
            }
        },
    }
</script>
